import React from 'react';
import { Link } from 'react-router-dom';
import { BiLogoFacebook } from 'react-icons/bi';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiOutlineInstagram } from 'react-icons/ai';

export default function footer() {
  return (
    <div>
        <div className='footer'>
          <div className='container inner_container'>
            <div className='row'>
                <div className='col-lg-5 left_sec mb-2'>
                <p>Copyright © 2023 All Right Reserved Savagecrown</p>
                </div>
                <div className='col-lg-4 center_sec'>
                <div className='center_sec_list'>
                   <Link to="/terms">Terms of Sevices </Link> | <Link to='/privacy'>Privacy Policy </Link>
                </div>
                </div>
                <div className='col-lg-3 end_sec'>
                    <div className='social_icons'>
         <BiLogoFacebook/>
         <AiOutlineInstagram/>
         <AiOutlineTwitter/>
         </div>
                </div>
              
            </div>
          </div>
          

        </div>



    </div>
  )
}
