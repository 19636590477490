import React ,{useState}from 'react';
import {Link ,NavLink} from "react-router-dom"
import {Dropdown ,Navbar,Container,NavDropdown,Nav }from 'react-bootstrap';
import Connectmodal from '../components/modal/connectwalletmodal';
import brand_logo from '../assets/images/header_logo.png'
import { FaTwitter,FaDiscord } from 'react-icons/fa';
export default function Header() {
  const [connectModal, setConnectModal] = useState(false);   
  return (
    <div>
      <div className='header'>
      <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand >
          <Link to="/">
          <img src={brand_logo} alt='logo' className='img-fluid header_logo'/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="">
            <NavLink  to="/">HOME</NavLink>
            <NavLink  to="/store">STORE</NavLink>
            <NavLink  to="/quest">QUESTS</NavLink>
            <NavLink  to="/armory">ARMORY</NavLink>
            
          </Nav>
          <div className='social_icons'>
            <Link to=''><FaTwitter/></Link>
            <Link to=''> <FaDiscord/></Link>
          </div>
          <button type='button' className='btn connect_btn'  onClick={() => setConnectModal(true)} >Connect Wallet</button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      </div>
      <Connectmodal
        show={connectModal}
        onHide={() => setConnectModal(false)}
      />
    </div>
  )
}
