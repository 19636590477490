import React ,{useState} from 'react';
import {Tab ,Tabs }from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import CharCard from '../components/charcard';


import inner_line from '../assets/images/inner_line.png'
import Mintingmodal from '../components/modal/mintingmodal';
export default function Armory() {
  const [mintingModal, setMintingModal] = useState(false);   
  const [buydetail, setBuydetail] = useState({});   

  const [charcard, setCharcard] =useState([
    {   
      char_img:require('../assets/images/char_img.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"show"
    },
    {   
      char_img:require('../assets/images/char_img.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"show"
    },
    {   
      char_img:require('../assets/images/char.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"show"
    },
    {   
      char_img:require('../assets/images/char_img.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"reveal"
    },
    {   
      char_img:require('../assets/images/char7.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"show"
    },
    {   
      char_img:require('../assets/images/char_img.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"reveal"
    },
    {   
      char_img:require('../assets/images/char_img.png'),
      clan:"DESERTLAND",
      trait:"DISHONEST",
       level: "DUKE",
       type:"show"
    },
{
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },

])
const [weaponcard, setWeaponcard] =useState([
  {   
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },
  {   
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },
  {   
    char_img:require('../assets/images/char.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },
  {   
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"reveal"
  },
  {   
    char_img:require('../assets/images/char7.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },
  {   
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"reveal"
  },
  {   
    char_img:require('../assets/images/char_img.png'),
    clan:"DESERTLAND",
    trait:"DISHONEST",
     level: "DUKE",
     type:"show"
  },
{
  char_img:require('../assets/images/char_img.png'),
  clan:"DESERTLAND",
  trait:"DISHONEST",
   level: "DUKE",
   type:"show"
},

])


  return (
    <div className='inner_pages'>
      <Header/>
      <div className='quest_pages'>
<div className='inner_sec'>

<div className='container inner_container'>

  
  <div className='top_sec'>
    <div className='row'>
      <div className='text-center mb-4'>
      <p className='page_title'>Armory</p>
      <img src={inner_line} alt='inner_border' className='img-fluid' width={120}/>
      </div>
      <div className='desc_sec'>
      <p className='page_desc'>View your collection of Savage Crown NFTs</p>
      <div className='coin_sec'>
  <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x 250</p>
  </div>
  </div>
    </div>
    
  </div>
  <div className='tab_sec'>
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="tabs_design mb-3"
    >
      <Tab eventKey="home" title="CHARACTERS">

        <div className='row'>
        {charcard.map((e) => (
          <div className='col-xl-3 col-md-6  mb-4' onClick={() =>{if(e.type != "reveal"){setBuydetail(e); setMintingModal(true)} } }>
        <CharCard
        
        char_img={e.char_img} clan={e.clan} trait={e.trait} level={e.level} type={e.type}/>
          </div>
        ))}
      
        </div>

        <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" >Load More</button>
        </div>
      
      </Tab>
      <Tab eventKey="profile" title="EQUIPMENT">
      <div className='row'>
        {weaponcard.map((e) => (
          <div className='col-xl-3 col-md-6  mb-4'>
        <CharCard
        
        char_img={e.char_img} clan={e.clan} trait={e.trait} level={e.level} type={e.type}/>
          </div>
        ))}
      
        </div>

        <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" >Load More</button>
        </div>
      </Tab>
      
    </Tabs>
    </div>
</div>
  

</div>
</div>


      <Footer/>
     {buydetail && <Mintingmodal
          imgbuy={buydetail.char_img}
        show={mintingModal}
        onHide={() => setMintingModal(false)}
         buymodal
      />
     }
    </div>
  )
}
