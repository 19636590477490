import React from 'react';
import { Link } from "react-router-dom";
import coin from '../assets/images/store_coin.png'
export default function Storecard({
  title,
  store_img,
  desc,
  price
}
) {
  return (
    <div>
      <div className='card store_card_style'>
        <img src={store_img} className='img-fluid store_img' alt='store_img' />
        <div className='store_frame'></div>
        <p className='title'>{title}</p>
        <p className='desc'>{desc}</p>
        <p className='price'><img src={coin} alt='coin' className='img-fluid store_coin' width={15} />{price}</p>
      </div>

    </div>
  )
}
