import React ,{useState} from 'react';
import Footer from '../layout/footer';
import Header from '../layout/header';
import Marquee from "react-fast-marquee";
import banner_crown from '../assets/images/bannerlogosat.png';
import leftsec from '../assets/images/leftsecnewsat.png';
import rightsec from '../assets/images/rightsecnewsat.png';
import king_img from '../assets/images/king_img.png';
import grad_bg from '../assets/images/gradient.png';
import leftflag_sec from '../assets/images/leftflagnew.png';
import leftflag_sec1 from '../assets/images/flags-left2.png';
import rightflag_sec from '../assets/images/rightflagnew.png';
import rightflag_sec1 from '../assets/images/flags-right2.png';
import end_line from '../assets/images/end_line.png';
import characters_img from '../assets/images/characters.png';
import training_img from '../assets/images/training_img.png';
import road_map_head from '../assets/images/roadmap_head.png';
import faq_img from '../assets/images/faq_img.png';
import tower_full from '../assets/images/pagetower.png';
import mountain_final from '../assets/images/mountains_finale.png';
import Mintingmodal from '../components/modal/mintingmodal';
export default function Home() {
  const [mintingModal, setMintingModal] = useState(false);   
  const [charimg, setCharimg] =useState([
    {
      char_img: require('../assets/images/char.png')
    },
    {
      char_img: require('../assets/images/char1.png')
    },
    {
      char_img: require('../assets/images/char2.png')
    },
    {
      char_img: require('../assets/images/char3.png')
    },
    {
      char_img: require('../assets/images/char4.png')
    },
    {
      char_img: require('../assets/images/char5.png')
    },
    {
      char_img: require('../assets/images/char6.png')
    },
    {
      char_img: require('../assets/images/char7.png')
    },
    {
      char_img: require('../assets/images/char8.png')
    },
    {
      char_img: require('../assets/images/char9.png')
    },


  ])
  const [teamcards, setTeamcards] =useState([
    {
      team_img: require('../assets/images/teamimg.png')
    },
    {
      team_img: require('../assets/images/teamimg1.png')
    },
    {
      team_img: require('../assets/images/teamimg2.png')
    },
    {
      team_img: require('../assets/images/teamimg3.png')
    },


  ])
  return (

    <div className='homepage'>
       <Header />
    <div className='homepage_inner'>
  
         <div className='banner_home'>
        <div className='container custom_container'>
            <div className='row'>
                <div className='banner_sec'>
                    {/* <div className='fogwrapper'>
                <div id="foglayer_01" class="fog">
  <div class="image01"></div>
  <div class="image02"></div>
</div>
<div id="foglayer_02" class="fog">
  <div class="image01"></div>
  <div class="image02"></div>
</div>
<div id="foglayer_03" class="fog">
  <div class="image01"></div>
  <div class="image02"></div>
</div>
</div> */}
<div className='anim_fog'>

</div>




                    {/* <div className='fog_bg'></div> */}
                    
            <img src={grad_bg} alt='grad' className='grad_bg img-fluid'/>
            <img src={leftsec} className="left_img"alt='rabbit'/>
            <img src={rightsec} className="right_img"alt='rightsec'/>
            <img src={leftflag_sec} className="leftflag_sec"alt='leftflag_sec'/>
            {/* <img src={leftflag_sec1} className="leftflag_sec1"alt='leftflag_sec1'/> */}
            <img src={rightflag_sec} className="rightflag_sec"alt='rightflag_sec'/>
            {/* <img src={rightflag_sec1} className="rightflag_sec1"alt='rightflag_sec1'/> */}
                    <div className='row first_row'>
                        <img src={banner_crown}  alt='crown' className='img-fluid banner_crown' width={100}/>
                        {/* <div className='banner_rippon_sec'>
                            <p>Mint and stake your savages
Join our adventure and earn lots of rewards!</p>
<div className='banner_bnt_sec'>
<button  type="button" className='btn banner_btn'  onClick={() => setMintingModal(true)}>MINT NOW</button>
</div>
                        </div> */}
                    </div>
                   

                </div>
                <div className='back_grad'></div>
            </div>
            </div>
            </div>
            <div className='second_sec row'>
              <div>
              
                    <img src={king_img} alt='king_img' className='king_img img-fluid'/>
                    </div>
                </div>
            <div className='third_desc_sec'>
              <div className='container inner_container'>
              <div className='row'>
              
              <div className='content_sec'>
              
                   <p>Savage Crown is a utility-enabled PFP project with immersive storytelling and interactive lore. Owning a Savage allows you to “train” granting loot drops the longer you stake them. The Savage Tower opens periodically allowing all Savages a chance to challenge the Savage King.</p>


              <img src={end_line} alt='end_line' className='end_line' width={200} />
              </div>
              </div>
              </div>

                </div>

                <div className='patternsec'>
                  <div className='container inner_container'>
                    <div className='row'>
                  <div className='card_sec'>
                    <div className='design_card'>
                      <p>In a kingdom far far away…</p>
                      <p>At the center of the Kingdom lies the Savage Tower. Within the Savage Tower lives the Savage King. A top the Savage King’s head sits the Savage Crown.

The Savage Crown contains ultimate power and governance over all the Kingdom. Warriors from every land across the Kingdom make their journey to the Savage Tower to take on the challenge to become the next Savage King.

What lies within the tower is only known by those who have entered it and the lucky few who have lived to exit it. Standing for a millennia, no creature knows where the Savage Tower came from, it has always just been. A symbol of mystery living in the backdrop of every village across the Kingdom.
</p>
                    </div>

                  </div>
                  </div>
                  </div>
                </div>
                <div className='charactor_sec'>
                  <div className='container inner_container'>
                    <div className='row'>
                  <div className='content_sec'>
                    <div className='img_sec'>
                      <img src={characters_img} alt='character_img' className='char_img1' width={500}/>
                    </div>
                    <p className='sec_title mt-4'>Characters</p>
                    <p className='sec_desc'>Creatures come from 5 different lands surrounding the Savage Tower. Each Savage has a unique combination of abilities, strengths, weapons and armor suited for taking on the Savage Tower.</p>
                    <img src={end_line} alt='end_line' className='end_line' width={200} />
                  </div>

                  
                  </div>
                
                  </div>
                  <div className='marquee_sec'>
                  <Marquee speed={200} direction='right'>
                     <div className='scrollanimchar'>
                  {charimg.map((e) => (
                    <div>
                    <img src={e.char_img} alt='chr_img' className='chr_img'/>
                    </div>
                  ))}
                </div>
                </Marquee>
                <Marquee speed={200}  direction='left'>
                <div className='scrollanimchar'>
                  {charimg.map((e) => (
                    <img src={e.char_img} alt='chr_img' className='chr_img'/>
                  ))}
                  </div>
                </Marquee>
                  </div>
                  {/* <div className='marquee_sec'>
                  <marquee behavior="scroll" direction="right" scrollamount="12">
                  {charimg.map((e) => (
                    <img src={e.char_img} alt='chr_img' className='chr_img'/>
                  ))}
                  </marquee>
                  <marquee behavior="scroll" direction="left" scrollamount="12">
                  {charimg.map((e) => (
                    <img src={e.char_img} alt='chr_img' className='chr_img'/>
                  ))}
                  </marquee>
                  </div> */}
                </div>
                <div className='training_sec'>
                <div className='container inner_container'>
                <div className='row first_row'>
                  <div className='col-lg-6 leftsec text-center'>
                    <img src={training_img} alt='training_img' className='training_img img-fluid' />

                  </div>
                  <div className='col-lg-6 rightsec'>
                     <p className='sec_title'>Training</p>
                     <p className='sec_desc'>Train your savage to level up, find loot and gain an advantage when challenging the Savage Tower. Work hard to become the next Savage King and the world’s treasures could be yours.</p>

                    </div>
                  
                 </div>
                 <img src={end_line} alt='end_line' className='end_line' width={200} />

</div>                </div>
                <div className='roadmap_sec'>
                  <div className='container inner_container'>
                   <div className='row'>
                 
                  <div className='top_sec'>
                    <p className='title'>Our Roadmap</p>
                  </div>
                  </div>
              <div className='full_map'>
                   <div className='mapsec top_sec'>
                    <div className='row first_row'>
                    <div className='col-lg-2'></div>
                      <div className='col-lg-5'>
                        <p className='sec_title'>Phase 1</p>
                      <p className='sec_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis turpis quam, ac finibus diam imperdiet ut. Nulla tortor elit, faucibus non tellus sed, pellentesque fermentum massa. Proin non viverra elit. Etiam dolor ligula, euismod id nunc a, aliquet gravida neque.</p>
                      </div>
                    
                      <div className='col-lg-5'>
                   
                        </div>
                    </div>
                    <div className='row second_row'>
                      <div className='col-lg-5'>
                    
                      </div>
                      <div className='col-lg-2'></div>
                      <div className='col-lg-5'>
                     
                      <p className='sec_title'>Phase 2</p>
                      <p className='sec_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis turpis quam, ac finibus diam imperdiet ut. Nulla tortor elit, faucibus non tellus sed, pellentesque fermentum massa. Proin non viverra elit. Etiam dolor ligula, euismod id nunc a, aliquet gravida neque.</p>
                     
                      </div>
                        </div>
                        <div className='row third_row'>
                        <div className='col-lg-5'>
                     
                     <p className='sec_title'>Phase 3</p>
                     <p className='sec_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam convallis turpis quam, ac finibus diam imperdiet ut. Nulla tortor elit, faucibus non tellus sed, pellentesque fermentum massa. Proin non viverra elit. Etiam dolor ligula, euismod id nunc a, aliquet gravida neque.</p>
                     </div>
                   
                      <div className='col-lg-2'></div>
                      <div className='col-lg-5'>
                      {/* <img src={road_map5} alt='roadmap' width={250} className='img-fluid road_map2'/> */}
    
                      </div>
                        </div>
                    
                   </div>
                
                   </div>
                 
                   
                   </div>
                 

                </div>



              <div className='faq_sec'>
                <div className='container inner_container'>
                 <div className='row'>
               
                <div className='col-lg-5 left_sec'>
                  <p className='sec_title'>Frequently asked
questions</p>
<div className='glow_sec'>
<div className='img_glow'> </div>
        <img src={faq_img} className='faq_img' width={500} alt="faq_img"/>
        </div>
                </div>
                <div className='col-lg-7 right_sec'>
                <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div>
                </div>
                </div>
                </div>

              </div>

             <div className='team_sec'>
              <div className='container inner_container'>
                    <div className='row title_top'>
                      <p className='sec_title'>Our Team</p>
                    </div>

                <div className='row card_sec'>
                {teamcards.map((e) => (
                  <div className='col-lg-3 col-md-6 mb-4  '>
                    <div className='team_card'>
                      <div className='team_card_bg'></div>
                      <img src={e.team_img} alt='team_img' className='team_img'/>

                    </div>
                  </div>
                ))}
                </div>
                <div className='end_line_sec'>
                <img src={end_line} alt='end_line' className='end_line' width={200} />
                </div>
              </div>
             </div>
            
        </div>
 <div className='tower_sec'>
<div className='bottom_sec'>
<div className='content_sec'>
  <div className='leftside'>
    <p className='sec_title'>Savage Tower</p>
    <p className='sec_desc'>Locked for over 1000 years, no creature truly knows what goes on behind the Tower doors. Myths of immortality and unimaginable wealth for those who have conquered the tower have been passed down for generations, but the truth seems to have been locked away for good. Until one day, the giant Tower doors have suddenly opened and an invitation to anyone who dares to challenge the Savage Tower.
</p>
  </div>
</div>

<img src={tower_full} alt='tower' className='img-fluid tower_full'/>

</div>






   </div>
   <div className='footer_sec'>
    <Footer/>
   </div>
    <Mintingmodal
        show={mintingModal}
        onHide={() => setMintingModal(false)}
      />
        </div>
  
  )
}
