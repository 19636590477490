import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../src/assets/css/styles.css';
import Home from "../src/pages/home";
import Quest from "../src/pages/quest";
import Minting from "../src/pages/Minting";
import QuestDetail from './pages/questdetail';
import Myarmory from "../src/pages/myarmory";
import Store from "./pages/store";
import Privacy from "../src/pages/privacy";
import Terms from "../src/pages/terms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/quest" element={<Quest />}/>
        <Route exact path="/minting" element={<Minting />}/>
        <Route exact path="/questdetail" element={<QuestDetail />}/>
        <Route exact path="/questdetailactive" element={<QuestDetail />}/>
        <Route exact path="/questdetailcompleted" element={<QuestDetail />}/>
        <Route exact path="/armory" element={<Myarmory />}/>
        <Route exact path="/store" element={<Store />}/>
        <Route exact path="/privacy" element={<Privacy />}/>
        <Route exact path="/terms" element={<Terms />}/>
        {/* <Route path="/forgot" element={<Forgot />}/>
        <Route path="/password" element={<Password />}/>
        <Route path="/otpverfication" element={<Otpverfication />}/>
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/orders" element={<Orders />}/>
         */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
