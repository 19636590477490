import React , { useState } from 'react';
import {Link} from "react-router-dom";
import reveal_img from '../assets/images/reveal_card.png';
import create_img from '../assets/images/create_card.png';
import add_img from '../assets/images/add_btn.png';
import Revealmodal from './modal/revealmodal';
export default function Charcard({
  char_img,
  clan,
  trait,
  level,
  type,
  onClick
}
) {
  const [revealModal, setRevealModal] = useState(false);   
  return (
    <div>
        { type == 'reveal' ?   
    <div className='card char_card_style'>
  
    <img src={reveal_img} className='img-fluid char_img' alt='char_img'/>
    <div className='reveal_sec'>
      <Link className="reveal_btn"  onClick={() => setRevealModal(true)} to="">REVEAL</Link>

    </div>

  </div>
    : 
    type == "create" ?
    <div className='card char_card_style create_card'>

    <img src={create_img} className='img-fluid create_img' alt='create_img'/>
     
     <div className='btn_sec'>
     <img src={add_img} className='img-fluid add_img' alt='add_img'/>
      </div>
      <p className='create_desc'>x1 Savage Crown Warrior</p>

  </div>
  :
  <div className='card char_card_style'>
  
    <img src={char_img} className='img-fluid char_img' alt='char_img'/>
    <div className='detail_sec'>
      <div>
        <p className='title'>CLAN</p>
        <p className='title_desc'>{clan}</p>
      </div>
      <div>
        <p className='title'>TRAIT</p>
        <p className='title_desc'>{trait}</p>
      </div>
      <div>
        <p className='title'>CLASS</p>
        <p className='title_desc'>{level}</p>
      </div>


    </div>







  </div>

  }

   <Revealmodal
        show={revealModal}
        onHide={() => setRevealModal(false)}
      />

    </div>
  )
}
