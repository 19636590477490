import React from 'react';
import {Link} from "react-router-dom"
import clk from '../assets/images/clk.png'
import checkmark from '../assets/images/checkmark.png';
import Countdown from 'react-countdown';
export default function Questcard({ 
    quest_img,
    desc,
    category,
    days,
    nftscount,
     tokens,
     type
}) {
  return (
    <div>
      
    <div className='card quest_card_style'>
         <div className='row first_row'>
            <div className='col-md-4 col-lg-4 col-xl-5  leftside'>
              <div className='img_sec'>
                <img src={quest_img} alt='quest_img' className='quest_pic img-fluid' />
                </div>
            </div>
            <div className='col-md-8 col-lg-8 col-xl-7   rightside'>
            { type == 'active' ?     <p className='time_duration'><Countdown date={Date.now() + 100000000} /></p>:
            <></>
            }

                <p className='title_card'>{desc}</p>
                <p className='catg'>{category}</p>
                <p className='count'><img src={clk} className='img-fluid' alt='clock' width={15}/> {days} Days</p>
                <p className='count'><img src={checkmark} className='img-fluid' alt='clock' width={15}/> {nftscount} Savage NFTs</p>
                <p className='token_label'>{tokens}</p>
            </div>
         </div>
         <div className='bottom_link'>
         { type == 'completed' ?  
            <Link className='final_link' state={{data:type}} to="/questdetail">Quest Completed</Link>:
            <Link to="/questdetail" state={{data:type}}>View Quest</Link>

        }
         </div>
             


    </div>
    </div>
  )
}
