import React ,{useState} from 'react';
import {Tab ,Tabs }from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import QuestCard from '../components/questcard';


import inner_line from '../assets/images/inner_line.png'
export default function Quest() {
  const [availblecard, setAvailblecard] =useState([
    {   
      quest_img:require('../assets/images/quest_pic.png'),
      desc:"Collect Iron Ore for the Blacksmith",
      category:"Qualification",
       days: "7",
       nftscount:"2",
       tokens:"Earn 70 Savage Tokens",
       type:"active"
    },
    {   
      quest_img:require('../assets/images/quest_pic.png'),
      desc:"Collect Iron Ore for the Blacksmith",
      category:"Qualification",
       days: "7",
       nftscount:"2",
       tokens:"Earn 70 Savage Tokens",
       type:"active"
   },
   {   
    quest_img:require('../assets/images/quest_pic.png'),
    desc:"Collect Iron Ore for the Blacksmith",
    category:"Qualification",
     days: "7",
     nftscount:"2",
     tokens:"Earn 70 Savage Tokens",
     type:"available"
 },
 {   
  quest_img:require('../assets/images/char_img.png'),
  desc:"Collect Iron Ore for the Blacksmith",
  category:"Qualification",
   days: "7",
   nftscount:"2",
   tokens:"Earn 70 Savage Tokens",
   type:"available"
},
{   
  quest_img:require('../assets/images/quest_pic.png'),
  desc:"Collect Iron Ore for the Blacksmith",
  category:"Qualification",
   days: "7",
   nftscount:"2",
   tokens:"Earn 70 Savage Tokens",
   type:"completed"
},
{   
  quest_img:require('../assets/images/quest_pic.png'),
  desc:"Collect Iron Ore for the Blacksmith",
  category:"Qualification",
   days: "7",
   nftscount:"2",
   tokens:"Earn 70 Savage Tokens",
   type:"completed"
},


])


  return (
    <div className='inner_pages'>
      <Header/>
      <div className='quest_pages'>
<div className='inner_sec'>

<div className='container inner_container'>

  
  <div className='top_sec'>
    <div className='row'>
      <div className='text-center mb-4'>
      <p className='page_title'>Quests</p>
      <img src={inner_line} alt='inner_border' className='img-fluid' width={120}/>
      </div>
      <div className='desc_sec'>
      <p className='page_desc'>Quests are a collector's opportunity to earn exclusive items and rewards for the NFTs</p>
      <div className='coin_sec'>
  <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x 250</p>
  </div>
  </div>
    </div>
    
  </div>
  <div className='tab_sec'>
    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="tabs_design mb-3"
    >
      <Tab eventKey="home" title="Available">

        <div className='row'>
        {availblecard.filter((item=>item.type == "available")).map((e) => (
          <div className='col-xl-6 mb-4'>
        <QuestCard
        
        quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}/>
          </div>
        ))}
      
        </div>

        <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" >Load More</button>
        </div>
      
      </Tab>
      <Tab eventKey="profile" title="Active">
      <div className='row'>
        {availblecard.filter((item=>item.type == "active")).map((e) => (
          <div className='col-xl-6 mb-4'>
           <QuestCard
        
        quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}/>
          </div>
        ))}
      
        </div>

        <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" >Load More</button>
        </div>
      </Tab>
      <Tab eventKey="contact" title="Completed" >
      <div className='row'>
        {availblecard.filter((item=>item.type == "completed")).map((e) => (
          <div className='col-xl-6 mb-4'>
        <QuestCard
        
        quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}/>
          </div>
        ))}
      
        </div>

        <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" >Load More</button>
        </div>
      </Tab>
    </Tabs>
    </div>
</div>
  

</div>
</div>


      <Footer/>

    </div>
  )
}
