import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png';
import Storecard from '../components/storecard';

import top_line from '../assets/images/header_border.png';
import bottom_line from '../assets/images/footer_border.png';
import inner_line from '../assets/images/inner_line.png';
import Mintingmodal from '../components/modal/mintingmodal';
export default function Store() {
  const [mintingModal, setMintingModal] = useState(false);   
  const [buydetail, setBuydetail] = useState({});   
  const [storecard, setStorecard] = useState([
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },
    {
      store_img: require('../assets/images/store_pic.png'),
      title: "Mining Cart",
      desc: "Lorem ipsum dolar sit amet",
      price: "2000",

    },

  ])


  return (
    <div className='inner_pages'>
      <Header />
      <div className='store_pages'>
        <div className='inner_sec'>

          <div className='container inner_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title'>Store</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  <p className='page_desc'>Use Savage Tokens to purchase upgrades and items for your Savage Warriors.</p>
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x 250</p>
                  </div>
                </div>
              </div>

            </div>
            <div className='store_line_sec'>
              <img src={top_line} className='img-fluid frameborderstyle' alt='store_line' />
            </div>
            <div className='store_card_sec'>
              <div className='row'>
                {storecard.map((e) => (
                  <div className='col-xl-3 col-md-6 mb-4' onClick={() =>{{setBuydetail(e); setMintingModal(true)} } }>
                    <Storecard
                      store_img={e.store_img} desc={e.desc} price={e.price} title={e.title} />
                  </div>
                ))}

              </div>
            </div>
            <div className='store_line_sec'>
              <img src={bottom_line} className='img-fluid frameborderstyle bottom' alt='store_line' />
            </div>
          </div>


        </div>
      </div>


      <Footer />
      {buydetail && <Mintingmodal
          imgbuy={buydetail.store_img}
        show={mintingModal}
        onHide={() => setMintingModal(false)}
        buymodal
      />
     }

    </div>
  )
}
