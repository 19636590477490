import React from 'react';
import {Link} from "react-router-dom"
import clk from '../assets/images/clk.png'
import checkmark from '../assets/images/checkmark.png'
import Countdown from 'react-countdown';
export default function Questcard({ 
    quest_img,
    desc,
    category,
    days,
    nftscount,
     tokens,
     type
}) {
  return (
    <div>
      
    <div className='card quest_card_style quest_detailcard_style'>
         <div className='row first_row'>
            <div className='col-lg-5 col-xl-5 col-xxl-4 leftside'>
            <div className='img_sec'>
                <img src={quest_img} alt='quest_img' className='quest_pic img-fluid' />
                </div>
            </div>
            <div className='col-lg-7 col-xl-7 col-xxl-8  rightside'>
            { type == 'active' ?     <p className='time_duration'> <Countdown date={Date.now() + 100000000} /></p>:
            <></>
            }

                <p className='title_card'>{desc}</p>
                <p className='token_label'>{tokens}</p>
                <div>
                  <p className='det_title'>Requirements</p>
                  <p className='det_desc'>2 savage Crown NFTs</p>
                  <p className='det_title'>Duration</p>
                  <p className='det_desc'>720 Hours</p>
                </div>
                
            </div>
         </div>
         <div className='bottom_link'>
         { type == 'available' ?  
            <Link state={{data:type}} to="/questdetail">BEGIN QUEST</Link>:
            type == 'active' ?  
            <Link to="/questdetail" state={{data:type}}>WITHDRAW CHARACTERS</Link>:
            <Link to="/questdetail" state={{data:type}}>COMPLETED</Link>

        }
         </div>
             


    </div>
    </div>
  )
}
