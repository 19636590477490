import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom"
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import QuestCarddetail from '../components/questcarddetail';
import CharCard from '../components/charcard';
import Nodata from '../components/nodata';

import inner_line from '../assets/images/inner_line.png'
export default function Questdetail() {
  const location = useLocation()
  { console.log('loc', location); }
  const questtabpage = location.state?.data;
  { console.log('local', questtabpage); }
  const [detailcard, setDetailcard] = useState([
    {
      quest_img: require('../assets/images/quest_pic.png'),
      desc: "Stake 2 Savage Crown Character NFTs to receive 70 Savage Tokens. Savage Tokens can be used to purchase equipment for your characters. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      category: "Qualification",
      duration: "7",
      nftscount: "2",
      tokens: "Earn 70 Savage Tokens",
      type: "completed"
    },
    {
      quest_img: require('../assets/images/quest_pic.png'),
      desc: "Stake 2 Savage Crown Character NFTs to receive 70 Savage Tokens. Savage Tokens can be used to purchase equipment for your characters. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      category: "Qualification",
      duration: "7",
      nftscount: "2",
      tokens: "Earn 70 Savage Tokens",
      type: "active"
    },
    {
      quest_img: require('../assets/images/quest_pic.png'),
      desc: "Stake 2 Savage Crown Character NFTs to receive 70 Savage Tokens. Savage Tokens can be used to purchase equipment for your characters. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      category: "Qualification",
      duration: "7",
      nftscount: "2",
      tokens: "Earn 70 Savage Tokens",
      type: "available"
    },
    


  ])
  const [charcard, setCharcard] = useState([
    {
      char_img: require('../assets/images/char_img.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },
    {
      char_img: require('../assets/images/char_img.png'),
      clan: "DESERTLAND",
      trait: "DISHONEST",
      level: "DUKE",
      type: "show"
    },


  ])


  return (
    <div className='inner_pages'>
      <Header />
      <div className='quest_pages quest_detail'>
        <div className='inner_sec'>

          <div className='container inner_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title'>MINE IRON ORE</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  <p className='page_desc'>Ends: 03/23/2023</p>
                  <div className='coin_sec'>
                    <p className='balance_coin'><img src={coin} alt='coin' className='img-fluid' width={25} /> x 250</p>
                  </div>
                </div>
              </div>

            </div>

            <div className='detail_card_sec'>
            {
                    questtabpage == "available" ?
                     <div className='row'>
                    {detailcard.filter((item => item.type =="available")).map((e) => (
                      <div className='col-lg-12'>
                        <QuestCarddetail
                          quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}
                        />
                      </div>
                    ))}
    
                  </div>
                  :
                  questtabpage == "active" ?
                  <div className='row'>
                 {detailcard.filter((item => item.type =="active")).map((e) => (
                   <div className='col-lg-12'>
                     <QuestCarddetail
                       quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}
                     />
                   </div>
                 ))}
 
               </div>
               :
               <div className='row'>
                 {detailcard.filter((item => item.type =="completed")).map((e) => (
                   <div className='col-lg-12'>
                     <QuestCarddetail
                       quest_img={e.quest_img} desc={e.desc} category={e.category} days={e.days} nftscount={e.nftscount} tokens={e.tokens} type={e.type}
                     />
                   </div>
                 ))}
 
               </div>
                 
                  }
            </div>
            <div className='recent_quest'>
              <div className='row'>
                <div className='text-center mb-4'>
                {
                    questtabpage == "available" ? 
                  <p className='page_title'>Characters on this Quest</p> : questtabpage == "active" ?   <p className='page_title'>Characters on this Quest</p> : <p className='page_title'>Characters that Completed this Quest</p>}
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
              </div>

            {  questtabpage == "available" ?
              <div className='row card_rows'>
                {charcard.filter((item  => item.type="create")).map((e) => (
                  <div className='col-xl-3 mb-4'>
                    <CharCard

                      char_img={e.char_img} clan={e.clan} trait={e.trait} level={e.level} type={e.type} />
                  </div>
                ))}

              </div>
              :
              <div className='row card_rows'>
              {charcard.map((e) => (
                <div className='col-xl-3 mb-4'>
                  <CharCard

                    char_img={e.char_img} clan={e.clan} trait={e.trait} level={e.level} type={e.type} />
                </div>
              ))}

            </div>
                }
            </div>

          </div>


        </div>
      </div>


      <Footer />

    </div>
  )
}
