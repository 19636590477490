import React ,{useState} from 'react';
import {Tab ,Tabs }from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';


import coin from '../assets/images/coin.png'
import file from '../assets/images/file.png'
export default function Minting() {

  const handleFileUploadClick = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.click();
  };

  const handleFileSelected = (event) => {
    const selectedFile = event.target.files[0];
  };
  return (
    <div className='inner_pages'>
      <Header/>
      <div className='quest_pages mining_pages'>
        <div className='inner_sec'>
        <div className='container inner_container'>


<div className='top_sec'>
  <div className='row'>
    <p className='page_title'>Minting</p>
    <p className='page_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
  </div>
 
</div>
<div className='row mint_form'>
    <div>
      <p className='upload_title'>Upload</p>
      <p className='upload_type'>PNG, GIF or WEBP Max 50mb.</p>
    </div>
    <div className='col-lg-6 leftsec'>
      <div className='upload_frame' onClick={handleFileUploadClick} style={{ cursor: 'pointer' }}>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileSelected}
      />

        <img src={file} className='img-fluid file_upload_img' width={100} alt='file'/>
        <p className='first'>Upload Files</p>
        <p className='second'>Drag Your Items To Upload</p>
        <p className='third'>PNG, GIF or Webp Maximum file size 50mb</p>

      </div>

    </div>
    <div className='col-lg-6 form_sec'>
      <div className='row mb-4'>
    <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Price</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Price Of The NFT"/>
  </div>
  </form>
  </div>
  <div className='row mb-4 upload_row'>
    <div className='col-9'>
    <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Upload Excel</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Price Of The NFT"/>
  </div>
  </form>
    </div>
    <div className='col-3'>
      
    <div className='loadmore_btn_sec'>
          <button className='btn loadmore_btn' alt="loadmore" onClick={handleFileUploadClick}  >Upload</button>
        </div>
    </div>
   
  </div>
  <div className='row mb-4'>
    <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Set royalty %</label>
    <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="0"/>
  </div>
  </form>
  </div>
      </div>
  </div>
  <div className='btn_sec'>
    <button type='button'  className='btn btn-minting'>Start Minting</button>
  </div>
</div>
        </div>



</div>


      <Footer/>

     
    </div>
  )
}
