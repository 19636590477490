import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import inner_line from '../../assets/images/inner_line.png'
export default function Revealmodal(props) {
  return (
    <div >
         <Modal  {...props} centered   size="md" className='revealmodal'>
            <div className='bg_pattern'></div>
            <div className='close_btn'>
              <p className='close_text' onClick={props.onHide}>X</p>
            </div>
        <Modal.Header >
          <Modal.Title><p>Do you Want to reveal this NFT?</p>
          <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div className='btn_sec'>
            
            <Button className='modal_btn' variant="secondary" onClick={props.onHide}>
            YES
          </Button>
          <Button className='modal_btn' variant="primary" onClick={props.onHide}>
           NO
          </Button>
          </div></Modal.Body>
       
      </Modal>
    </div>
  )
}
