import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import inner_line from '../../assets/images/inner_line.png';
import connect1 from '../../assets/images/connect1.png';
import connect2 from '../../assets/images/connect2.png';
import connect3 from '../../assets/images/connect3.png';
export default function Connectwalletmodal(props) {
  return (
    <div >
         <Modal  {...props} centered   size="md" className='revealmodal'>
            <div className='bg_pattern'></div>
            <div className='close_btn'>
              <p className='close_text' onClick={props.onHide}>X</p>
            </div>
        <Modal.Header >
          <Modal.Title><p>Connect your wallet</p>
          <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div className='btn_sec'>
            
            <Button className='modal_btn' variant="secondary" onClick={props.onHide}>
              <img src={connect1} alt="metamask" className='img-fluid'/>
          </Button>
          <Button className='modal_btn' variant="primary" onClick={props.onHide}>
          <img src={connect2} alt="metamask" className='img-fluid'/>
          </Button>
          <Button className='modal_btn' variant="primary" onClick={props.onHide}>
          <img src={connect3} alt="metamask" className='img-fluid'/>
          </Button>
          </div></Modal.Body>
       
      </Modal>
    </div>
  )
}
