import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Footer from '../layout/footer';
import Header from '../layout/header';
import coin from '../assets/images/coin.png'
import Storecard from '../components/storecard';

import store_line from '../assets/images/store_line.png'
import inner_line from '../assets/images/inner_line.png'
export default function Privacy() {
  const [privacycontent, setPrivacycontent] = useState([
    {
      heading:"",
      desc:"Nam a justo blandit, ullamcorper urna ac, hendrerit velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas elementum id leo vitae facilisis."

    },
    {
      heading:"",
      desc:"Nunc dui ex, placerat sit amet elementum quis, rutrum at tortor. Aenean tincidunt luctus malesuada. In lobortis erat non lacinia volutpat. In malesuada condimentum lacinia. Morbi lacinia eget nulla at tincidunt. Suspendisse ornare ante accumsan nisl interdum, vel dignissim tellus gravida."

    },
    {
      heading:"malesuada condimentum lacinia. Morbi lacinia eget nulla at tincidunt.",
      desc:"Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus sed tellus ultrices, rutrum risus ac, congue magna. Aenean ornare dapibus tellus non fermentum. Donec est leo, pellentesque porta interdum et, congue nec ligula."

    },
    {
      heading:"Aenean ornare dapibus tellus non fermentum.",
      desc:"Etiam id facilisis turpis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc sed laoreet tortor.Sed vitae fringilla sapien. Nullam vitae diam nec dui tempus semper. Aliquam erat volutpat. Phasellus sed mi sed dolor euismod bibendum eget vel ligula. Nulla facilisis leo nec dolor pharetra aliquet. Morbi sodales interdum leo eget volutpat."

    },
    {
      heading:"Suspendisse potenti. Donec dictum facilisis ex eu accumsan.",
      desc:"Nullam tristique ex vitae massa ultrices pellentesque. Nam maximus, augue quis ornare tempus, ipsum urna tempus eros, in convallis odio mauris a neque. Nulla quis enim imperdiet, posuere nulla quis, interdum eros. Suspendisse potenti. Donec dictum facilisis ex eu accumsan. Suspendisse suscipit tortor ut arcu sollicitudin dictum."

    },
    {
      heading:"quam libero hendrerit magna, in ultrices libero.",
      desc:"Quisque vitae libero bibendum, viverra libero nec, bibendum ex. Pellentesque iaculis orci id neque tempor finibus. Sed varius, est eu feugiat mattis, diam enim ullamcorper arcu, eu porta lectus justo nec sapien."

    },
    {
      heading:"malesuada condimentum lacinia. Morbi lacinia eget nulla at tincidunt.",
      desc:"Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus sed tellus ultrices, rutrum risus ac, congue magna. Aenean ornare dapibus tellus non fermentum. Donec est leo, pellentesque porta interdum et, congue nec ligula."

    },
    {
      heading:"",
      desc:"Vestibulum eleifend, velit quis sollicitudin tristique, quam libero hendrerit magna, in ultrices libero diam sed mauris. "

    },
    {
      heading:"Mauris urna elit, feugiat quis ultricies eget, molestie ac sem.",
      desc:"Proin accumsan justo eu lorem ornare eleifend. Aenean pharetra hendrerit erat. Ut fringilla metus vel enim cursus, vel gravida nulla fermentum. Sed porta orci sit amet arcu rutrum placerat. Curabitur semper odio eros, quis rhoncus dui efficitur ac. Mauris urna elit, feugiat quis ultricies eget, molestie ac sem. Maecenas ut lectus sit amet lacus consectetur tempor in ac orci."

    },
    {
      heading:"",
      desc:"Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ligula erat, condimentum ac vestibulum ac, dictum quis metus.Nam maximus, augue quis ornare tempus, ipsum urna tempus eros, in convallis odio mauris a neque. Nulla quis enim imperdiet, posuere nulla quis, interdum eros. Suspendisse potenti. Donec dictum facilisis ex eu accumsan. Suspendisse suscipit tortor ut arcu sollicitudin dictum."

    },
    {
      heading:"malesuada condimentum lacinia. Morbi lacinia eget nulla at tincidunt.",
      desc:"Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus sed tellus ultrices, rutrum risus ac, congue magna. Aenean ornare dapibus tellus non fermentum. Donec est leo, pellentesque porta interdum et, congue nec ligula."

    },
  ])

  return (
    <div className='inner_pages'>
      <Header />
      <div className='cms_pages'>
        <div className='inner_sec'>

          <div className='container inner_container'>


            <div className='top_sec'>
              <div className='row'>
                <div className='text-center mb-4'>
                  <p className='page_title'>Terms of services</p>
                  <img src={inner_line} alt='inner_border' className='img-fluid' width={120} />
                </div>
                <div className='desc_sec'>
                  <p className='page_desc'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </div>
              </div>

            </div>
          <div className='cms_content_sec'>
         
            <ul>
            {privacycontent.map((e) => (
              <li>
                <p className='heading'>{e.heading}</p>
                <p className='desc'>{e.desc}</p>
              </li>
               
               ))}
            </ul>
               
          </div>
           
           
          </div>


        </div>
      </div>


      <Footer />

    </div>
  )
}
