import React ,{useState}from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import mining_img from '../../assets/images/char_img.png'

export default function Mintingmodal(props) {
  const [count, setCount] = useState(0);

  const increaseCount = () => {
    setCount(count + 1);
  };
console.log("logsss",props);
  const decreaseCount = () => {
    setCount(count - 1, 0);
  };
  return (
    <div >
         <Modal  {...props} centered   size="md" className='mintingmodal'>
            <div className='bg_pattern'></div>
            <div className='close_btn'>
              <p className='close_text' onClick={props.onHide}>X</p>
            </div>
        <Modal.Header >
          <Modal.Title><p>COLLECT YOUR NFT</p>
         
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                   <div className='text-center'>
                    {props.imgbuy ? <img src={props.imgbuy} alt='create_nft' className='img-fluid mining_nft_img'/> : <img src={mining_img} alt='create_nft' className='img-fluid mining_nft_img'/> }
                  
                  </div>
                  <div className='detail_sec'>
                  {props.buymodal ?
                      <></> : <div className='lists'>
                      <p className='label'>Remaining</p>
                      <p>8000/8000</p>
                    </div>}
                    <div className='lists'>
                      <p className='label'>price</p>
                      <p className='range'>0.15 ETH</p>
                    </div>
                    <div className='lists'>
                      <p className='label'>Quantity</p>
                      <div className='counter_sec'>
                      <p className='count_btn' onClick={decreaseCount}>-</p>           
     
      <p>{count}</p>
      <p className='count_btn' onClick={increaseCount}>+</p>
                      </div>
                      <p  className='range'>0.30 ETH</p>
                    </div>
                  </div>

             </Modal.Body>
       <Modal.Footer>
       <div className='btn_sec'>
        {props.buymodal ? <button className='btn banner_btn' onClick={props.onHide}>BUY NOW</button> : <button className='btn banner_btn' onClick={props.onHide}>MINT NOW</button>}
 

          </div>

          <div className='bg_pattern_bottom'></div>
       </Modal.Footer>
      </Modal>
    </div>
  )
}
